// extracted by mini-css-extract-plugin
export var clientLabel = "project-module--client-label--Phxrx";
export var clientName = "project-module--client-name--2b8Y4";
export var crumbs = "project-module--crumbs--apRp2";
export var descriptionBox = "project-module--description-box--WKwiS";
export var desktop = "project-module--desktop--AiP9G";
export var header = "project-module--header--jHvge";
export var hero = "project-module--hero--TcHul";
export var heroGrid = "project-module--hero-grid--f1kpm";
export var layout = "project-module--layout--E-TJd";
export var mainImageBox = "project-module--main-image-box--7U1KL";
export var mainImageRatio = "project-module--main-image-ratio--mbeu3";
export var mobile = "project-module--mobile--7CesC";
export var mockupDesktopSection = "project-module--mockup-desktop-section--99E+s";
export var mockupMobileSection = "project-module--mockup-mobile-section--dlg4j";
export var navigation = "project-module--navigation--oAhl2";
export var noPrev = "project-module--no-prev--somnk";
export var sectionNext = "project-module--section-next--RSYBR";
export var work = "project-module--work--6R5Vq";
export var workLabel = "project-module--work-label--jMxIB";
export var workText = "project-module--work-text--2GsCv";