import React from 'react';
import { graphql } from 'gatsby';
import { Image } from '@alterpage/gatsby-plugin-image';

import {
    layout,
    hero,
    heroGrid,
    crumbs,
    header,
    clientLabel,
    clientName,
    work,
    workLabel,
    workText,
    mainImageBox,
    mainImageRatio,
    descriptionBox,
    mockupMobileSection,
    desktop,
    mobile,
    mockupDesktopSection,
    sectionNext,
    navigation,
    noPrev,
} from './project.module.scss';
import { gridNarrow } from '../styles/grid.module.scss';
import { relations } from '../config/relations';
import { IProject } from '../models/project';
import { useT } from '../hooks/use-translation';

import MainLayout from '../layouts/main-layout';
import Section from '../components/hoc/section';
import Markdown from '../components/hoc/markdown';
import Button from '../components/atoms/button';
import Breadcrumbs from '../components/molecules/breadcrumbs';

interface IProjectPageProps {
    readonly pageContext: {
        prevId?: number;
        nextId?: number;
    };
    readonly data: {
        project: IProject;
        nextProject: Pick<IProject, 'pathname'> | null;
        prevProject: Pick<IProject, 'pathname'> | null;
    };
}

const ProjectPage: React.FC<IProjectPageProps> = ({ data, pageContext }) => {
    const { prevId, nextId } = pageContext;
    const { t } = useT();
    const { project, nextProject, prevProject } = data;
    const { color, media, client, lead, workScope, description, breadcrumbs } = project;

    return (
        <MainLayout
            className={layout}
            showContactForm={true}
            contactFormProps={{
                titleProps: { children: t('contact.form.title') },
                description: t('project.contact.form.description'),
                inputContentLabel: t('project.contact.form.content.label'),
                buttonProps: {
                    color: 'blue',
                    kind: 'normalDark',
                },
            }}
        >
            <section className={`${hero} ${gridNarrow}`} style={{ background: color }}>
                <Breadcrumbs className={crumbs} breadcrumbs={breadcrumbs} />
                <div className={heroGrid}>
                    <div className={header}>
                        <p className={clientLabel}>{t('project.client.label')}</p>
                        <h1 className={clientName}>{client}</h1>
                    </div>
                    <div className={work}>
                        <p className={workLabel}>{t('project.work.label')}</p>
                        {workScope && <Markdown className={workText}>{workScope}</Markdown>}
                    </div>
                    <Image
                        className={`${mainImageBox} ${desktop}`}
                        ratioClass={mainImageRatio}
                        media={media}
                        objectFit="contain"
                    />
                    <Image
                        className={`${mainImageBox} ${mobile}`}
                        ratioClass={mainImageRatio}
                        media={media}
                        objectFit="contain"
                        relation={relations.mainImageMobile}
                    />
                </div>
            </section>
            <Section
                theme="light"
                titleProps={{ Tag: 'h2', children: lead }}
                contentDisplay="left"
                height="auto"
                circleDisplay="none"
                gridType="narrow"
            >
                {description && <Markdown className={descriptionBox}>{description}</Markdown>}
            </Section>
            <Section
                className={mockupMobileSection}
                circleDisplay="none"
                height="auto"
                gridType="narrow"
            >
                <Image
                    className={desktop}
                    media={media}
                    relation={relations.projectMockupMobile}
                    useFallback={false}
                    objectFit="contain"
                />
                <Image
                    className={mobile}
                    media={media}
                    relation={relations.projectMockupMobileSecondary}
                    fallback={relations.projectMockupMobile}
                    objectFit="contain"
                />
            </Section>
            <Section
                className={mockupDesktopSection}
                circleDisplay="none"
                height="auto"
                style={{ background: color }}
            >
                <Image
                    className={desktop}
                    media={media}
                    relation={relations.projectMockupDesktop}
                    objectFit="contain"
                />
                <Image
                    className={mobile}
                    media={media}
                    relation={relations.projectMockupDesktopSecondary}
                    fallback={relations.projectMockupDesktop}
                    objectFit="contain"
                />
            </Section>
            <Section
                titleProps={{ Tag: 'h2', children: t('project.palette') }}
                circleDisplay="none"
                height="auto"
                contentDisplay="left"
                gridType="narrow"
            >
                <Image
                    className={desktop}
                    media={media}
                    relation={relations.projectPalette}
                    objectFit="contain"
                />
                <Image
                    className={mobile}
                    media={media}
                    relation={relations.projectPaletteSecondary}
                    fallback={relations.projectPalette}
                    objectFit="contain"
                />
            </Section>
            <Section
                circleDisplay="none"
                height="auto"
                style={{ background: color }}
                gridType="narrow"
            >
                <Image
                    className={desktop}
                    media={media}
                    relation={relations.projectTypography}
                    objectFit="contain"
                />
                <Image
                    className={mobile}
                    media={media}
                    relation={relations.projectTypographySecondary}
                    fallback={relations.projectTypography}
                    objectFit="contain"
                />
            </Section>
            {(nextProject || prevProject) && (
                <Section
                    titleProps={{ Tag: 'h2', children: t('project.projects') }}
                    className={sectionNext}
                    circleDisplay="none"
                    height="auto"
                    gridType="narrow"
                >
                    <div className={`${navigation} ${!prevId ? noPrev : ''}`}>
                        {prevId && prevProject && (
                            <Button as="link" to={prevProject.pathname}>
                                {t('project.prev')}
                            </Button>
                        )}
                        {nextId && nextProject && (
                            <Button as="link" to={nextProject.pathname}>
                                {t('project.next')}
                            </Button>
                        )}
                    </div>
                </Section>
            )}
        </MainLayout>
    );
};

export const query = graphql`
    query ProjectPage($locale: String!, $projectId: Int!, $nextId: Int, $prevId: Int) {
        project(locale: { eq: $locale }, projectId: { eq: $projectId }) {
            ...projectFields
        }
        nextProject: project(locale: { eq: $locale }, projectId: { eq: $nextId }) {
            pathname
        }
        prevProject: project(locale: { eq: $locale }, projectId: { eq: $prevId }) {
            pathname
        }
    }
`;

export default ProjectPage;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';
